<template>
  <div class="mt-5 container is-centered has-text-centered">
    <a class="button is-size-3" :href="url" target="_blank">Hier geht's zur Buchung!</a>
  </div>
</template>

<script>

export default {
  name: 'Booking',
  props: {
    url: String
  },
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>

<style>


</style>
