<template>
  <div class="container gallery">
    <div :id="galleryID">
      <div class="columns is-multiline">
        <div
            v-for="(value, name, index) in images" :key="index"
            class="column is-one-fifth"
        >
          <a :href="`https://tanis-ferienwohnung.de/gallery/buehl/buehl_${value}.jpg`">
            <img :src="`https://tanis-ferienwohnung.de/gallery/buehl/buehl_${value}.jpg`" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'photoswipe/dist/photoswipe.css'

export default {
  name: 'GalleryBuehl',
  props: {
    galleryID: String,
    images: Array,
  },
  setup(props) {
    return {
      imagesData: props.images,
      images: [
        13, 3, 18, 12, 24, 2, 8, 9, 22, 23, 26, 11, 29, 16, 20, 21, 19, 14, 10, 15, 4, 1, 5, 6, 7, 17, 25, 27, 28
      ]
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#' + this.$props.galleryID,
        children: 'a',
        pswpModule: () => import('photoswipe'),
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {},
};
</script>

<style>

.pswp img {
  max-width: none;
  object-fit: contain;
}

</style>
