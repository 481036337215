<template>
  <div class="home">
    <BigHeader />
    <Booking url="https://www.holidu.de/d/54057338" />
    <AusstattungBuehl />
    <Booking url="https://www.holidu.de/d/54057338" /><br/><br/>
    <GalleryBuehl />
    <Booking url="https://www.holidu.de/d/54057338" />
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import Booking from "@/components/Booking.vue";
import AusstattungBuehl from "@/components/AusstattungBuehl.vue";
import GalleryBuehl from "@/components/GalleryBuehl.vue";

export default {
  name: 'Home',
  components: {
    GalleryBuehl,
    AusstattungBuehl,
    Booking,
    BigHeader,
  }
}
</script>
