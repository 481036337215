import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'
import Buehl from "@/views/Buehl.vue";
import Ottersweier from "@/views/Ottersweier.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/buehl',
    name: 'Buehl',
    component: Buehl
  },
  {
    path: '/ottersweier',
    name: 'Ottersweier',
    component: Ottersweier
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/imprint',
    name: 'Impressum',
    component: Imprint
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  }
]

const router = new VueRouter({
	routes,
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

export default router
