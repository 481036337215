<template>
  <div class="home">
    <BigHeader />
    <Intro />
    <div class="container mt-6">
      <div class="columns is-vcentered has-text-centered">
        <div class="column is-half">
          <div class="mt-5 container is-centered has-text-centered">
            <a class="button is-size-3" href="https://www.holidu.de/d/54057338" target="_blank">Bühl "Städtle" - Buchung</a>
          </div>
        </div>
        <div class="column is-half">
          <div class="mt-5 container is-centered has-text-centered">
            <a class="button is-size-3" href="https://www.holidu.de/d/54046360" target="_blank">Ottersweier "Dörfle" - Buchung</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import Intro from "@/components/Intro";
import Gallery from "@/components/GalleryOttersweier.vue";
import Booking from "@/components/Booking";

export default {
  name: 'Home',
  components: {
    Intro,
    BigHeader,
    Gallery,
    Booking
  }
}
</script>
