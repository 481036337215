import { render, staticRenderFns } from "./AusstattungBuehl.vue?vue&type=template&id=54107f34&scoped=true&"
import script from "./AusstattungBuehl.vue?vue&type=script&lang=ts&"
export * from "./AusstattungBuehl.vue?vue&type=script&lang=ts&"
import style0 from "./AusstattungBuehl.vue?vue&type=style&index=0&id=54107f34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54107f34",
  null
  
)

export default component.exports