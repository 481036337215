<template>
  <div class="container gallery">
    <div :id="galleryID">
      <div class="columns is-multiline">
        <div
            v-for="(value, name, index) in images" :key="index"
            class="column is-one-fifth"
        >
          <a :href="`https://tanis-ferienwohnung.de/gallery/ottersweier/${value}.jpeg`">
            <img :src="`https://tanis-ferienwohnung.de/gallery/ottersweier/${value}.jpeg`" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'photoswipe/dist/photoswipe.css'

export default {
  name: 'GalleryOttersweier',
  props: {
    galleryID: String,
    images: Array,
  },
  setup(props) {
    return {
      imagesData: props.images,
      images: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19
      ]
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#' + this.$props.galleryID,
        children: 'a',
        pswpModule: () => import('photoswipe'),
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {},
};
</script>

<style>

.pswp img {
  max-width: none;
  object-fit: contain;
}

</style>
