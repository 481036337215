<template>
  <div class="home">
    <BigHeader />
    <Booking url="https://www.holidu.de/d/54046360" />
    <AusstattungOttersweier />
    <Booking url="https://www.holidu.de/d/54046360" /><br/><br/>
    <GalleryOttersweier gallery-i-d="TEST" im={[]} />
    <Booking url="https://www.holidu.de/d/54046360" />
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import Gallery from "@/components/GalleryOttersweier.vue";
import AusstattungOttersweier from "@/components/AusstattungOttersweier.vue";
import Booking from "@/components/Booking.vue";
import GalleryOttersweier from "@/components/GalleryOttersweier.vue";

export default {
  name: 'Home',
  components: {
    GalleryOttersweier,
    Booking,
    AusstattungOttersweier, Gallery,
    BigHeader,
  }
}
</script>
