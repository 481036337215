<template>
  <div class="home">
    <div class="container">
      <p class="is-size-1">Datenschutz</p>
      <p>
        <b>1. Verantwortlicher</b><br/>
        Verantwortlicher im Sinne der EU-Datenschutzgrundverordnung (DSGVO) ist diejenige natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Für die auf diese Webseite verarbeiteten personenbezogenen Daten ist Verantwortlicher im Sinne der DSGVO:  Tanja Herrbrich-Person - Näharena, Sternenstraße 5, 77815 Bühl, Telefon (07223) 24566,<br/>
        E-Mail kontakt@tanis-ferienwohnung.de (im Folgenden „wir“).<br/><br/>

        <b>2. Wenn Sie unsere Webseite besuchen</b><br/>
        Wenn Sie unsere Webseite besuchen, erhebt unser Server die folgenden Informationen von Ihrem Endgerät: Browser-Typ und -Version, Betriebssystem, die zuvor besuchte Webseite („Referrer“), IP-Adresse und Zeitpunkt des Seitenaufrufs.<br/>
        Wir erheben und verarbeiten diese Daten, um den störungsfreien Betrieb unserer Webseite sicherzustellen und einen Missbrauch unserer Leistungen erkennen, abwehren und verfolgen zu können. Ferner nutzen wir die erhobenen Daten zu statistischen Zwecken, um etwa auszuwerten, mit welchen Endgeräten und Browsern unsere Webseite aufgerufen wird, um auf dieser Basis unser Angebot laufend an die Bedürfnisse der Nutzer anzupassen und zu verbessern. Diese Datenverarbeitung erfolgt auf Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO.<br/>
        Alle oben genannten personenbezogenen Daten löschen wir spätestens zwölf Monate nach ihrer Erhebung.<br/><br/>

        <b>3. Wenn Sie bei uns buchen</b><br/>
        Wenn Sie bei uns eine Buchung aufgeben, verarbeiten wir Ihren Namen, die Adresse und Ihre E-Mail-Adresse, wie Sie sie im Zuge des Buchungsvorgangs angeben. Soweit Sie bei Ihrer Buchung freiwillig weitere Daten angeben (z.B. eine abweichende Rechnungsadresse oder eine Telefonnummer), verarbeiten wir auch diese Daten.<br/>
        Wir verarbeiten diese Daten elektronisch zur ordnungsgemäßen Vertragserfüllung. Diese Datenverarbeitung erfolgt auf Grundlage von Artikel 6 Absatz 1 Buchstabe b DSGVO.<br/>
        Wir halten diese Daten gespeichert, bis alle gegenseitigen Ansprüche aus dem jeweiligen Vertragsverhältnis mit Ihnen vollständig erledigt und die handels- und steuerrechtlichen Aufbewahrungsfristen abgelaufen sind, denen wir unterliegen.<br/>
        Für einen Vertragsschluss zwischen Ihnen und uns ist es erforderlich, dass wir Ihren Namen, die Adresse und Ihre E-Mail-Adresse erhalten. Die Erforderlichkeit der Bereitstellung dieser Daten ergibt sich aus gesetzlichen Vorschriften (etwa § 312i Abs. 1 Ziffer 3 BGB, § 14 Abs. 4 UStG). Ohne Bereitstellung dieser Daten können Sie mit uns daher keinen Vertrag schließen.<br/>
        Buchungsdaten werden bei demjenigen Dienstleister gespeichert, der die Buchungssoftware für unseren Webauftritt zur Verfügung stellt. Die Verarbeitung Ihrer Daten findet innerhalb des EWR statt.<br/><br/>

        <b>4. Bezahlung</b><br/>
        Für die Bezahlung Ihrer Buchung erhebt und verarbeitet der von Ihnen gewählte Zahlungsdienstleister Ihren Namen, Ihre E-Mail-Adresse, Ihre Karten- oder Kontonummer und/oder weitere Daten, soweit für die von Ihnen gewählte Zahlungsmethode jeweils erforderlich. Es gelten insoweit ergänzend die Vertrags- und Datenschutzbestimmungen des von Ihnen gewählten Zahlungsdienstleisters.<br/>
        Beim Empfang einer Zahlung verarbeiten wir diejenigen Daten, die uns der Zahlungsdienstleister übermittelt.<br/>
        Wenn wir eine Überweisung auf unser Konto erhalten, verarbeiten wir insbesondere den Namen des überweisenden Kontoinhabers, die Kontonummer (IBAN und BIC) und den mitübermittelten Verwendungszweck.<br/>
        Die Verarbeitung erfolgt auf Grundlage von Artikel 6 Absatz 1 Buchstabe b DSGVO. Wir halten diese Daten gespeichert, bis alle gegenseitigen Ansprüche aus dem jeweiligen Vertragsverhältnis mit Ihnen vollständig erledigt und die handels- und steuerrechtlichen Aufbewahrungsfristen abgelaufen sind, denen wir unterliegen.<br/><br/>

        <b>5. Kontaktaufnahme</b><br/>
        Wenn Sie ein Kontaktformular oder eine Chat-Funktion auf unserer Webseite nutzen, verarbeiten wir die von Ihnen dabei eingegebenen Daten; dies können neben Ihrer Nachricht Ihr Name und Ihre E-Mail-Adresse sein.<br/>
        Wenn Sie uns eine Nachricht per E-Mail schicken, speichern wir Ihre Nachricht mit den damit übermittelten Absenderdaten (Name, E-Mail-Adresse und ggf. weitere von Ihrem E-Mail-Programm und den übermittelnden Servern hinzugefügte Informationen). Für den Empfang, die Speicherung und den Versand von E-Mails nutzen wir einen regionalen E-Mail-Anbieter, der für uns als Auftragsverarbeiter im Einklang mit Artikel 28 DSGVO tätig wird.<br/>
        Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse, Ihre Nachricht zu beantworten und auch auf eventuelle Folgefragen von Ihnen reagieren zu können (Artikel 6 Absatz 1 Buchstabe f DSGVO). Wir löschen die mit Ihrer Nachricht erhobenen Daten spätestens zwölf Monate nach der letzten mit Ihnen geführten Kommunikation zu Ihrem Anliegen, vorbehaltlich der Regelung im folgenden Absatz.<br/>
        Wenn Sie uns eine rechtlich relevante Erklärung zum Vertragsverhältnis übermitteln (z.B. einen Widerruf oder eine Reklamation), ist Rechtsgrundlage für die Verarbeitung, unabhängig vom Übermittlungsweg, auch Artikel 6 Absatz 1 Buchstabe b DSGVO. In einem solchen Fall löschen wir die mit Ihrer Erklärung zusammenhängenden Daten, sobald alle wechselseitigen Ansprüche aus dem Vertragsverhältnis endgültig erledigt und die handels- und steuerrechtlichen Aufbewahrungsfristen abgelaufen sind.<br/><br/>

        <b>6. Kommentare und Bewertungen</b><br/>
        Wenn Sie einen Kommentar oder eine Bewertung zu Ihrer Buchung schreiben, veröffentlichen wir Ihren Beitrag an entsprechender Stelle auf unserer Webseite zusammen mit Ihrem Nutzernamen, für den Sie auch ein Pseudonym angeben können. Um einem Missbrauch unseres Angebots entgegenzuwirken, speichern wir die IP-Adresse des Endgeräts, von dem Sie schreiben, für einen Zeitraum von zwölf Monaten (Artikel 6 Absatz 1 Buchstabe f DSGVO). Wir behalten uns vor, unsachliche oder thematisch unpassende Beiträge jederzeit zu löschen. Im übrigen löschen wir veröffentlichte Beiträge nur auf Aufforderung des jeweiligen Autors.<br/><br/>

        <b>7. Nutzung von Cookies</b><br/>
        Soweit Sie in die Verwendung von Cookies eingewilligt haben, legen wir ein oder mehrere „Cookies“ auf Ihrem Endgerät ab. Bei einem Cookie handelt es sich um eine kleine Textdatei, mit der wir Ihr Endgerät wiedererkennen können, wenn Sie unsere Webseite zu späterer Gelegenheit wieder besuchen. Mit Hilfe von Cookies können wir auch bestimmte Nutzerverhalten analysieren, z.B., welche Seitenbereiche Sie sich anschauen, wie lange Sie auf unserer Webseite bleiben und wann und wie oft Sie auf unsere Webseite zurückkehren. Von uns abgelegte Cookies werden spätestens zwölf Monate nach Ihrem letzten Besuch unserer Webseite gelöscht.<br/>
        Diese Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Artikel 6 Absatz 1 Buchstabe a DSGVO).<br/>
        Sie können das Ablegen von Cookies verhindern, indem Sie in die Cookie-Einstellungen Ihres Internetbrowsers gehen und dort für unsere Seite oder für alle Webseiten dem Ablegen von Cookies widersprechen. Dort können Sie auch bereits abgelegte Cookies wieder löschen.<br/><br/>

        <b>8. Social Media</b><br/>
        Auf unserer Homepage können Social-Media-Buttons eingeblendet sein; sie sind an den Logos der Social-Media-Plattformen (im Folgenden „Plattformen“) zu erkennen (Facebook: „f“-Logo, Instagram: quadratische Kamera). Es handelt sich dabei um Links zu den jeweiligen Plattformen mit Sitz in den USA. Ein Klick auf einen solchen Link ruft die Internetseite der jeweiligen Plattform auf, wobei die IP-Adresse des aufrufenden Endgeräts sowie die Adresse der Seite, von der aus verlinkt wird („Referrer“), an die aufgerufene Plattform in die USA übermittelt werden. Von uns selbst werden im Zusammenhang mit den Social-Media-Buttons jedoch keine Daten erhoben oder verarbeitet.<br/><br/>

        <b>9. Ihre Rechte</b><br/>
        Bezüglich der personenbezogenen Daten, die wir über Sie verarbeiten, stehen Ihnen die folgenden Rechte zu:<br/>
        Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Sie betreffende personenbezogene Daten verarbeiten. Ist dies der Fall, so teilen wir Ihnen die über Sie gespeicherten personenbezogenen Daten und die weiteren Informationen gemäß Artikel 15 Abs. 1 u. 2 DSGVO mit.<br/>
        Sie haben das Recht, Sie betreffende unrichtige personenbezogene Daten unverzüglich berichtigen zu lassen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie auch das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.<br/>
        Sie können von uns die unverzügliche Löschung der Sie betreffenden personenbezogenen Daten unter den Voraussetzungen von Artikel 17 Absatz 1 DSGVO verlangen, soweit deren Verarbeitung nicht gemäß Artikel 17 Absatz 3 DSGVO erforderlich ist.<br/>
        Sie können von uns die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn eine der Voraussetzungen von Artikel 18 Absatz 1 DSGVO vorliegt. Sie können die Einschränkung insbesondere anstelle einer Löschung verlangen.<br/>
        Wir werden jede Berichtigung oder Löschung Ihrer personenbezogenen Daten und eine Einschränkung der Verarbeitung allen Empfängern mitteilen, denen wir Sie betreffende personenbezogene Daten offengelegt haben, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Wir werden Sie zudem über diese Empfänger unterrichten, wenn Sie dies verlangen.<br/>
        Sie haben das Recht, die personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und können verlangen, dass wir diese Daten einem anderen Verantwortlichen ohne Behinderung übermitteln, soweit dies technisch möglich ist.<br/>
        Soweit eine Datenverarbeitung auf Ihrer Einwilligung beruht, haben Sie das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der Datenverarbeitung, die bis zu Ihrem Widerruf erfolgt ist, nicht berührt.<br/>
        WIDERSPRUCHSRECHT: AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, KÖNNEN SIE JEDERZEIT DER VERARBEITUNG DER SIE BETREFFENDEN PERSONENBEZOGENEN DATEN WIDERSPRECHEN; dieses Widerspruchsrecht besteht in Bezug auf diejenige Datenverarbeitung, die auf Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO zur Wahrung berechtigter Interessen unsererseits oder eines Dritten erfolgt, sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern, überwiegen. Wenn Sie Ihr Widerspruchsrecht ausüben, werden wir die betreffenden Daten nicht mehr verarbeiten, es sei denn, dass wir zwingende schutzwürdige Gründe für die Verarbeitung nachweisen können, die die Interessen, Rechte und Freiheiten von Ihnen überwiegen, oder dass die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.<br/>
        FÜR DEN FALL, DASS WIR PERSONENBEZOGENE DATEN FÜR DIE DIREKTWERBUNG (Z.B. NEWSLETTER) VERARBEITEN, KÖNNEN SIE JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG DER SIE BETREFFENDEN PERSONENBEZOGENEN DATEN ZUM ZWECKE DERARTIGER WERBUNG EINLEGEN MIT DER FOLGE, DASS WIR IHRE DATEN FÜR DIESE ZWECKE NICHT MEHR VERARBEITEN WERDEN.<br/>
        Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DSGVO verstößt, können Sie Beschwerde einlegen bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes. Das schließt anderweitige behördliche oder gerichtliche Rechtsbehelfe nicht aus.<br/>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
  components: {

  }
}
</script>
